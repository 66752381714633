import { Table, TableBody, TableContainer } from "@mui/material";
import RecentTradesTableHeader from "modules/tradeLists/components/RecentTrades/RecentTradesTableHeader";
import { CardRowSkeleton } from "modules/common/components/CardRowSkeleton";
import { TradeListType } from "modules/tradeLists/model/tradeList";

export const RecentTradesTableSkeleton = ({
  type,
}: {
  type: TradeListType;
}) => (
  <TableContainer sx={{ maxHeight: "509px" }}>
    <Table stickyHeader>
      <RecentTradesTableHeader />
      <TableBody>
        {[...Array(14)].map((_, index) => (
          <CardRowSkeleton key={`${index}-${type}`} columns={10} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);
