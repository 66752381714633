"use client";

import React from "react";
import { Box, Theme, useMediaQuery } from "@mui/material";
import { TradeItem } from "modules/tradeLists/model/tradeList";
import TradePreviewCard from "modules/tradeLists/components/RecentTrades/TradePreviewCard";

interface Props {
  items: TradeItem[];
}

const RecentTradesCards = ({ items }: Props) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <>
      {items.slice(0, mdDown ? items.length - 1 : 3).map((item, index) => (
        <Box
          key={`recent-trade-preview-${item.data.id}-${index}`}
          sx={{ scrollSnapAlign: "start" }}
        >
          <TradePreviewCard item={item} />
        </Box>
      ))}
    </>
  );
};

export default RecentTradesCards;
