"use client";
import React from "react";
import { Skeleton, TableCell, TableRow } from "@mui/material";
import { compactTableCellStyle } from "modules/common/styles";
import Box from "@mui/material/Box";
import useSkeletonWidths from "modules/common/hooks/useSkeletonWidths";

interface Props {
  height?: string;
  columns?: number;
}

const columnConfig = [
  { id: "checkbox", content: <></> },
  {
    id: "quantity",
    content: <Skeleton width={20} height={"100%"} variant={"text"} />,
  },
  {
    id: "name",
    content: (
      <Skeleton
        width={"100%"}
        height={"100%"}
        variant={"text"}
        sx={{ maxWidth: "200px" }}
      />
    ),
  },
  {
    id: "set",
    content: <Skeleton height={"16px"} width={"16px"} variant={"circular"} />,
  },
  {
    id: "language",
    content: (
      <Box>
        <Skeleton width={30} height={"18px"} variant={"rounded"} />
      </Box>
    ),
  },
  {
    id: "finishes",
    content: (
      <Box>
        <Skeleton height={"20px"} width={"20px"} variant={"circular"} />
      </Box>
    ),
  },
  {
    id: "condition",
    content: (
      <Box>
        <Skeleton
          width={35}
          height={"20px"}
          variant={"rounded"}
          sx={{
            borderRadius: "15px",
          }}
        />
      </Box>
    ),
  },
  {
    id: "price",
    content: (
      <Box>
        <Skeleton width={40} height={"100%"} variant={"text"} />
      </Box>
    ),
  },
  {
    id: "purchase_price",
    content: (
      <Box>
        <Skeleton width={40} height={"100%"} variant={"text"} />
      </Box>
    ),
  },
  {
    id: "added",
    content: (
      <Box>
        <Skeleton width={50} height={"100%"} variant={"text"} />
      </Box>
    ),
  },
  {
    id: "modified",
    content: (
      <Box>
        <Skeleton width={50} height={"100%"} variant={"text"} />
      </Box>
    ),
  },
  { id: "actions", content: <></> },
];

export const CardRowSkeleton = ({ height = "100%", columns }: Props) => {
  const { randomWidth, toRenderOrNotToRender } = useSkeletonWidths();
  const textSkeletonHeight = "100%";
  const visibleColumns = columns
    ? columnConfig.slice(0, columns)
    : columnConfig;

  return (
    <TableRow
      sx={{
        height: 32,
      }}
    >
      {visibleColumns.map((column, index) => (
        <TableCell key={index} sx={{ ...compactTableCellStyle }}>
          {column.id === "name" ? (
            <Skeleton
              width={randomWidth}
              height={textSkeletonHeight}
              variant={"text"}
            />
          ) : column.id === "finishes" || column.id === "purchase_price" ? (
            toRenderOrNotToRender ? (
              column.content
            ) : null
          ) : (
            column.content
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

export default CardRowSkeleton;
