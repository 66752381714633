"use client";
import React from "react";
import { Box, Paper, Theme, Typography, useMediaQuery } from "@mui/material";
import TradePreviewCardSkeleton from "modules/common/components/skeletons/trade/TradePreviewCardSkeleton";
import { RecentTradesTableSkeleton } from "modules/common/components/skeletons/trade/RecentTradesTableSkeleton";
import { TradeListType } from "modules/tradeLists/model/tradeList";

const RecentTradesSkeleton = ({
  title,
  type,
}: {
  title: string;
  type: TradeListType;
}) => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Paper
      sx={{
        bgcolor: "background.default",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        p: 2,
        gap: 2,
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            color: "text.primary",
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 1,
            overflowX: { xs: "auto", md: "unset" },
            scrollSnapType: { xs: "x mandatory", md: "none" },
            width: "100%",
          }}
        >
          {[...Array(mdDown ? 4 : 3)].map((_, index) => (
            <Box
              key={`recent-trade-preview-skeleton-${index}`}
              sx={{ scrollSnapAlign: "start" }}
            >
              <TradePreviewCardSkeleton />
            </Box>
          ))}
        </Box>
      </Box>

      {!mdDown && (
        <Box
          sx={{
            width: { xs: "100%", md: "70%" },
            flexGrow: 1,
            display: { xs: "none", md: "block" },
          }}
        >
          <RecentTradesTableSkeleton type={type} />
        </Box>
      )}
    </Paper>
  );
};

export default RecentTradesSkeleton;
